import React from "react";
import Grid from "@material-ui/core/Grid/Grid";
import Chip from "@material-ui/core/Chip/Chip";
import Pagination from "@material-ui/lab/Pagination/Pagination";

export const MessagePaginationInfo = ({
  startIndex,
  endIndex,
  conversation,
}) => {
  const startMessageNum = startIndex + 1;
  const endMessageNum = Math.min(endIndex, conversation.length);
  const messageRange = `Messages ${startMessageNum} - ${endMessageNum} of ${conversation.length}`;
  return <Chip variant={"outlined"} color={"primary"} label={messageRange} />;
};

const MessagePagination = ({ conversation, pageSize, setPage, page }) => {
  const totalPages = Math.ceil(conversation.length / pageSize);
  return (
    <Grid container direction={"row"} justify={"center"}>
      <Pagination
        count={totalPages}
        page={page}
        variant="outlined"
        onChange={(e, value) => {
          setPage(value);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }}
      />
    </Grid>
  );
};

export default MessagePagination;
