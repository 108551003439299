import React, { useState, useEffect } from "react";
import Fuse from "fuse.js";
import _ from "lodash";
import Grid from "@material-ui/core/Grid/Grid";
import MessageList from "./MessageList";
import TextField from "@material-ui/core/TextField/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  filterItem: {
    justifyContent: "center",
  },
  filterContainer: {
    marginBottom: 100,
  },
}));

const getFuseInstance = (conversation) => {
  return new Fuse(conversation, {
    keys: ["content"],
    includeScore: true,
    threshold: 0.2,
  });
};

const MessageSearch = ({ conversation, setSelectedMessage }) => {
  const classes = useStyles();

  const [results, setResults] = useState([]);

  useEffect(() => {
    setResults([]);
  }, [conversation]);

  const fuse = getFuseInstance(conversation);

  const debouncedSearchInputChange = _.debounce((searchText) => {
    if (!searchText) {
      setResults([]);
      return;
    }
    const searchResults = fuse.search(searchText);
    setResults(searchResults.map((r) => r.item));
  }, 150);

  const filterHandler = (type, e) => {
    const eventValue = e.target.value;
    switch (type) {
      case "search":
        debouncedSearchInputChange(eventValue);
        break;
    }
  };

  return (
    <Grid item container direction={"column"}>
      <Grid container className={classes.filterContainer}>
        <TextField
          id="standard-basic"
          label="Search"
          defaultValue={""}
          onChange={(e) => filterHandler("search", e)}
          fullWidth
        />
      </Grid>
      <MessageList
        messages={results}
        setSelectedMessage={setSelectedMessage}
        rightAlignCurrentUser={false}
      />
    </Grid>
  );
};

export default MessageSearch;
