import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ParticipantSelector = ({ participants, setSelectedParticipant }) => {
  const classes = useStyles();
  if (!participants) {
    return <span />;
  }
  return (
    <FormControl className={classes.formControl}>
      <InputLabel>Select Participant</InputLabel>
      <Select
        onChange={(e) => {
          setSelectedParticipant(e.target.value);
        }}
      >
        {participants.map((p) => (
          <MenuItem value={p}>{p}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ParticipantSelector;
