import React from "react";
import { DropzoneArea } from "material-ui-dropzone";
import { InsertDriveFile } from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dropzoneArea: {
    minHeight: "0px",
    margin: "3px",
    backgroundColor: "transparent",
  },
  dropzoneAreaParagraph: {
    display: "none",
  },
}));

const fixEncoding = (data) => {
  data = data.replace(/%/g, () => {
    return `%25`;
  });
  return data.replace(/\\u00[0-9a-f]{2}/g, (x) => {
    return `%${x[4]}${x[5]}`;
  });
};

const MessageUpload = ({ setLoadedFile }) => {
  const classes = useStyles();

  const handleChange = (files) => {
    if (files && files.length) {
      console.log(`loading ${files.length} file(s)`);
      for (let file of files) {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          console.log("loaded file");
          const data = JSON.parse(fixEncoding(fileReader.result));
          setLoadedFile(data);
        };
        fileReader.readAsText(file);
      }
    }
  };

  return (
    <DropzoneArea
      Icon={InsertDriveFile}
      maxFileSize={100000000}
      filesLimit={20}
      showPreviewsInDropzone={false}
      dropzoneText={"Load"}
      onChange={handleChange}
      dropzoneClass={classes.dropzoneArea}
      dropzoneParagraphClass={classes.dropzoneAreaParagraph}
    />
  );
};

export default MessageUpload;
