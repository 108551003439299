import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import InstagramDataInstruction from "./instagram/InstagramDataInstruction";
import FacebookDataInstruction from "./facebook/FacebookDataInstruction";

const INSTAGRAM = "instagram";
const FACEBOOK = "facebook";

const getInstructions = (platform, setLoadedFile) => {
  if (platform === INSTAGRAM) {
    return <InstagramDataInstruction setLoadedFile={setLoadedFile} />;
  } else if (platform === FACEBOOK) {
    return <FacebookDataInstruction setLoadedFile={setLoadedFile} />;
  }
};

const InstructionSelection = ({ setLoadedFile }) => {
  const [platform, setPlatform] = useState(INSTAGRAM);
  const handleChange = (event, newValue) => {
    setPlatform(newValue);
  };
  return (
    <div>
      <Tabs value={platform} onChange={handleChange}>
        <Tab label={"Instagram"} value={INSTAGRAM} />
        <Tab label={"Facebook"} value={FACEBOOK} />
      </Tabs>
      {getInstructions(platform, setLoadedFile)}
    </div>
  );
};

export default InstructionSelection;
