import moment from "moment";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar/ListItemAvatar";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar/Avatar";
import { blue, green, red, amber } from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid/Grid";
import ArrowRight from "@material-ui/icons/ArrowRight";

const useStyles = makeStyles({
  selectedMessage: {
    backgroundColor: "#d7db60",
  },
});

const senderAvatar = {};
const colorChoices = [blue, green, red, amber];
let nextColor = 0;

const getAvatarForSender = (sender) => {
  if (!(sender in senderAvatar)) {
    const character = sender[0].toUpperCase();
    const color = colorChoices[nextColor];
    nextColor = (nextColor + 1) % colorChoices.length;
    senderAvatar[sender] = (
      <Avatar style={{ backgroundColor: color[300] }}>{character}</Avatar>
    );
  }
  return senderAvatar[sender];
};

const getFormattedDate = (createdAt) => {
  return moment(createdAt).format("MMMM Do YYYY, h:mm:ss A");
};

const decodeWrapper = (content) => {
  try {
    return decodeURIComponent(content);
  } catch (e) {
    console.log(content);
    return content;
  }
};

const Message = ({
  message,
  currentUser,
  selectedMessage,
  setSelectedMessage,
  rightAlignCurrentUser,
}) => {
  const classes = useStyles();
  let textClassName = "";
  if (selectedMessage && selectedMessage.content === message.content) {
    textClassName = classes.selectedMessage;
  }

  const alignment = rightAlignCurrentUser && currentUser ? "right" : "left";
  let items = [
    <ListItemAvatar align={alignment}>
      {getAvatarForSender(message.sender_name)}
    </ListItemAvatar>,
    <ListItemText
      className={textClassName}
      align={alignment}
      primary={decodeWrapper(message.content)}
      secondary={getFormattedDate(message.timestamp_ms)}
    />,
  ];
  if (rightAlignCurrentUser && currentUser) {
    [items[0], items[1]] = [items[1], items[0]];
  }
  return (
    <ListItem>
      {items[0]}
      {items[1]}
      {setSelectedMessage && (
        <ListItemSecondaryAction>
          <IconButton
            onClick={() => {
              setSelectedMessage(message);
            }}
            edge="end"
          >
            <ArrowRight />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

const MessageList = ({
  messages,
  startIndex = 0,
  endIndex = 10,
  selectedParticipant,
  selectedMessage,
  setSelectedMessage,
  rightAlignCurrentUser = true,
}) => {
  return (
    <Grid container>
      <Grid item md={2} />
      <Grid item md={8}>
        <List>
          {messages
            .slice(startIndex, endIndex)
            .filter((i) => i.content)
            .map((message) => {
              return (
                <Message
                  message={message}
                  currentUser={message.sender_name === selectedParticipant}
                  selectedMessage={selectedMessage}
                  setSelectedMessage={setSelectedMessage}
                  rightAlignCurrentUser={rightAlignCurrentUser}
                />
              );
            })}
        </List>
      </Grid>
      <Grid item md={2} />
    </Grid>
  );
};

export default MessageList;
