import React, { useState, useEffect } from "react";
import MessageListWithPagination from "./MessageListWithPagination";
import MessageSearch from "./MessageSearch";
import ExchangeSelector from "./ExchangeSelector";
import ParticipantSelector from "./ParticipantSelector";
import Grid from "@material-ui/core/Grid/Grid";
import _ from "lodash";
import InstructionSelection from "./InstructionSelection";

import { makeStyles } from "@material-ui/core/styles";

import MessageUpload from "./MessageUpload";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 100,
    paddingTop: 25,
  },
}));

const getParticipantsKey = (participants) => {
  const sortedParticipants = _.sortBy(participants, ["name"]);
  return sortedParticipants.map((p) => p.name).join(", ");
};

const App = ({}) => {
  const classes = useStyles();

  const [loadedFile, setLoadedFile] = useState(null);
  const [exchanges, setExchanges] = useState(null);
  const [selectedExchangeKey, setSelectedExchangeKey] = useState(0);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [participants, setParticipants] = useState(null);
  const [selectedParticipant, setSelectedParticipant] = useState(null);

  useEffect(() => {
    if (!loadedFile) {
      return;
    }
    const participantsKey = getParticipantsKey(loadedFile.participants);
    let newExchanges = {};
    if (exchanges != null) {
      newExchanges = { ...exchanges };
    }
    const messages = _.reverse(loadedFile.messages);
    if (participantsKey in newExchanges) {
      const existingMessages = newExchanges[participantsKey];
      const index = _.sortedIndexBy(
        existingMessages,
        messages[0],
        (m) => m.timestamp_ms
      );
      existingMessages.splice(index, 0, ...messages);
    } else {
      newExchanges[participantsKey] = messages;
    }
    if (!selectedExchangeKey) {
      setSelectedExchangeKey(participantsKey);
    }
    setExchanges(newExchanges);
  }, [loadedFile]);

  useEffect(() => {
    if (!exchanges) {
      return;
    }
    const arr = [];
    for (let participants of Object.keys(exchanges)) {
      for (let participant of participants.split(", ")) {
        arr.push(participant);
      }
    }
    setParticipants(_.uniq(arr));
    if (!selectedParticipant && arr.length > 0) {
      setSelectedParticipant(arr[0]);
    }
  }, [exchanges]);

  useEffect(() => {
    setSelectedMessage(null);
  }, [selectedExchangeKey]);

  return (
    <div>
      <Grid container>
        <Grid container direction={"row-reverse"} justify={"center"} md={6}>
          <Grid item>
            <ExchangeSelector
              exchanges={exchanges}
              setSelectedExchangeKey={setSelectedExchangeKey}
            />
          </Grid>
        </Grid>
        <Grid item md={6}>
          <ParticipantSelector
            participants={participants}
            setSelectedParticipant={setSelectedParticipant}
          />
        </Grid>
      </Grid>

      {!exchanges && (
        <Grid className={classes.content} container direction="row">
          {/*<Grid item md={6}>*/}
          {/*<MessageUpload setExchanges={setExchanges} />*/}
          {/*</Grid>*/}
          {/*<Grid item md={8}>*/}
          <InstructionSelection setLoadedFile={setLoadedFile} />
          {/*<InstagramDataInstruction  />*/}
          {/*</Grid>*/}
        </Grid>
      )}

      {exchanges && (
        <Grid className={classes.content} container direction="row">
          <Grid item md={4}>
            <MessageSearch
              conversation={exchanges[selectedExchangeKey]}
              setSelectedMessage={setSelectedMessage}
            />
          </Grid>
          <Grid item md={8}>
            <MessageListWithPagination
              conversation={exchanges[selectedExchangeKey]}
              selectedMessage={selectedMessage}
              selectedParticipant={selectedParticipant}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default App;
