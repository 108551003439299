import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ExchangeSelector = ({ exchanges, setSelectedExchangeKey }) => {
  const classes = useStyles();
  if (!exchanges) {
    return <span />;
  }
  return (
    <FormControl className={classes.formControl}>
      <InputLabel>Select Exchange</InputLabel>
      <Select
        onChange={(e) => {
          setSelectedExchangeKey(e.target.value);
        }}
      >
        {Object.keys(exchanges).map((key) => {
          return <MenuItem value={key}>{key}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};

export default ExchangeSelector;
