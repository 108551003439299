import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid/Grid";
import MessageList from "./MessageList";
import MessagePagination, { MessagePaginationInfo } from "./MessagePagination";
import _ from "lodash";
import moment from "moment";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  messageList: {
    display: "contents",
  },
});

const getDateInsertPoint = (conversation, selectedDate) => {
  const date = moment(selectedDate);
  return _.sortedIndexBy(conversation, { timestamp_ms: date.valueOf() }, (i) =>
    moment(i["timestamp_ms"]).valueOf()
  );
};

const getPageForMessage = ({ conversation, selectedMessage, pageSize }) => {
  const startDate = selectedMessage && selectedMessage["timestamp_ms"];
  if (!conversation || !startDate) {
    return 1;
  }
  // This means we have to figure out the page for the specified start date.
  const index = getDateInsertPoint(conversation, startDate);
  if (index >= conversation.length) {
    return 1;
  }
  return Math.floor(index / pageSize) + 1;
};

const MessageListWithPagination = ({
  conversation,
  pageSize = 40,
  selectedMessage,
  setSelectedMessage,
  selectedParticipant,
}) => {
  const classes = useStyles();

  const [page, setPage] = useState(1);
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  useEffect(() => {
    const initialPage = getPageForMessage({
      conversation,
      selectedMessage,
      pageSize,
    });
    setPage(initialPage);
  }, [selectedMessage]);

  return (
    <Grid item container direction={"column"}>
      <Grid container direction={"row"} justify={"center"}>
        <MessagePaginationInfo
          startIndex={startIndex}
          endIndex={endIndex}
          conversation={conversation}
        />
      </Grid>
      <Grid item className={classes.messageList}>
        <MessageList
          messages={conversation}
          startIndex={startIndex}
          endIndex={endIndex}
          selectedParticipant={selectedParticipant}
          selectedMessage={selectedMessage}
          setSelectedMessage={setSelectedMessage}
        />
      </Grid>
      <Grid container direction={"row"} justify={"center"}>
        <MessagePagination
          conversation={conversation}
          pageSize={pageSize}
          setPage={setPage}
          page={page}
        />
      </Grid>
    </Grid>
  );
};

export default MessageListWithPagination;
