import React from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import App from "./App";
import PrivacyPolicy from "./PrivacyPolicy";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Typography from "@material-ui/core/Typography/Typography";
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
        cursor: 'pointer'
    }
}));

const RoutedApp = ({}) => {
    const classes = useStyles();
    let navigate = useNavigate();
    return (
        <div>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" className={classes.title} onClick={()=> {navigate("/")}}>
                        Chat History Explorer
                    </Typography>
                    <Button color="inherit" onClick={()=> {navigate("/")}}>App</Button>
                    <Button color="inherit" onClick={()=> {navigate("privacy")}}>Privacy Policy</Button>
                </Toolbar>
            </AppBar>
            <Routes>
                <Route path="*" element={<App />} />
                <Route path="privacy" element={<PrivacyPolicy />} />
            </Routes>
        </div>
    );
};

export default RoutedApp;
