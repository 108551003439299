import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import FolderIcon from "@material-ui/icons/Folder";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import TimerIcon from "@material-ui/icons/Timer";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import AppleIcon from "@material-ui/icons/Apple";
import LaptopWindowsIcon from "@material-ui/icons/LaptopWindows";
import MessageUpload from "../MessageUpload";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  content: {
    flex: 8,
  },
}));

const FacebookDataInstruction = ({ setLoadedFile }) => {
  const classes = useStyles();

  return (
    <Timeline>
      <TimelineItem>
        <TimelineOppositeContent>
          <Typography variant="body2" color="textSecondary">
            Step 1
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color={"primary"}>
            <PhoneIphoneIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent className={classes.content}>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">
              Request Data
            </Typography>
            <List>
              <ListItem>
                <Typography>
                  Navigate to{" "}
                  <Link
                    href={"https://www.facebook.com/dyi"}
                    target={"_blank"}
                    rel="noopener"
                  >
                    https://www.facebook.com/dyi
                  </Link>
                </Typography>
              </ListItem>
              <ListItem>Click Deselect All and select just Messages</ListItem>
              <ListItem>In the Format dropdown, select JSON</ListItem>
              <ListItem>Click Create File</ListItem>
            </List>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="secondary">
            <TimerIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent className={classes.content}>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">
              Wait
            </Typography>
            <List>
              <ListItem>
                You will receive an email right away acknowledging the request
              </ListItem>
              <ListItem>
                Wait to receive another email when the data is ready (could take
                hours)
              </ListItem>
            </List>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent>
          <Typography variant="body2" color="textSecondary">
            Step 2
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary">
            <CloudDownloadIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent className={classes.content}>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">
              Download
            </Typography>
            <List>
              <ListItem>
                <Typography>
                  Follow the link in the email or go directly to{" "}
                  <Link
                    href={"https://www.facebook.com/dyi/?tab=all_archives"}
                    target={"_blank"}
                    rel="noopener"
                  >
                    https://www.facebook.com/dyi/?tab=all_archives
                  </Link>
                </Typography>
              </ListItem>
              <ListItem>From there, click Download</ListItem>
            </List>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent>
          <Typography variant="body2" color="textSecondary">
            Step 3
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary">
            <FolderIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent className={classes.content}>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">
              Extract .zip File
            </Typography>
            <List>
              <ListItem>
                <AppleIcon />
                <>&nbsp;&nbsp;</>
                {"Double Click"}
              </ListItem>
              <ListItem>
                <LaptopWindowsIcon />
                <>&nbsp;&nbsp;</>
                {"Right Click, Extract All"}
              </ListItem>
            </List>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent>
          <Typography variant="body2" color="textSecondary">
            Step 4
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary">
            <CloudUploadIcon />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent className={classes.content}>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">
              Load
            </Typography>
            <List>
              <ListItem>
                Use the load widget below to select messages_x.json files
              </ListItem>
              <ListItem>
                <Breadcrumbs>
                  <Typography>messages</Typography>
                  <Typography>inbox</Typography>
                  <Typography>{"<conversation>"}</Typography>
                </Breadcrumbs>
              </ListItem>
            </List>
            <MessageUpload setLoadedFile={setLoadedFile} />
          </Paper>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};

export default FacebookDataInstruction;
